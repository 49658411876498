import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { resolver } from 'found';
import { IntlProvider, injectIntl, addLocaleData } from 'react-intl';
import configureStore from './store/configure';
import configureServices from './service/configure';
import createConfig from './service/config';
import Router from './router/router';
import { getBearerAuthHeader, initKeycloak } from 'common/service/keycloak/keycloak-utils';

const LANG = navigator.language;

let messages = {};
try {
  messages = require('../locale/en.json');
} catch (e) {}

addLocaleData(
  require(`react-intl/locale-data/${LANG.split('-')[0] || 'en'}`),
);

const app = async () => {
  // The following line is a temporary performance/concurrent writes fix until
  // backend is using OPA for authorization. When the app starts on a clean
  // session multiple requests are made to SMA which then queries NMS for
  // authentication data and saves the user into session causing stress on NMS
  // and storing the user object on the session multiple times causing issues
  // with concurrent writes to the database. By doing this call and waiting for
  // it to finish the session gets initialized nicely and does not cause
  // unnecessary stress on NMS.
  //
  // TODO: Remove the following line once OPA is in
  // use and SMA no longer relies on call to NMS
  await fetch('/ui/currentUser', { headers: { ...getBearerAuthHeader() } });

  const smaConfig = await createConfig();
  const LocalizedProvider = injectIntl(({ intl }) => {
    const services = configureServices(intl, smaConfig);
    const store = configureStore(intl, services);

    return (
      <Provider store={store}>
        <Router
          matchContext={{
            intl,
            store,
            services,
          }}
          resolver={resolver}
        />
      </Provider>
    );
  });

  ReactDOM.render((
    <IntlProvider locale={LANG} defaultLocale={LANG} messages={messages}>
      <LocalizedProvider />
    </IntlProvider>
  ), document.body.querySelector('.react-root'));
};

initKeycloak(app);
