import React from 'react';
import { compose } from 'ramda';
import withErrorBoundary from 'common/components/withErrorBoundary';
import { connect } from 'react-redux';
import { node } from 'prop-types';
import Notifications from 'common/mdc/notifications';
import { Vbox } from 'common/mdc/layout';

import {
  actions,
} from 'common/store/server/links';

import Header from './components/header';
import Drawer from './components/drawer';

import './style.scss';

class App extends React.PureComponent {
  componentDidMount() {
    this.props.loadLinks();
  }

  render() {
    const { children } = this.props;

    return (
      <div className="mdc-typography root">
        <Vbox>
          <Header />
          <div className="body mdc-toolbar-fixed-adjust">
            <Drawer />
            { React.Children.only(children) }
          </div>
          <Notifications />
        </Vbox>
      </div>
    );
  }
}

App.propTypes = {
  children: node.isRequired,
};

export default compose(
  connect(
    null,
    ({
      loadLinks: actions.load,
    }),
  ),
  withErrorBoundary,
)(App);
